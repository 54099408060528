/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(".rslides").responsiveSlides({
                    auto: true,             // Boolean: Animate automatically, true or false
                    speed: 500,             // Integer: Speed of the transition, in milliseconds
                    timeout: 5000,          // Integer: Time between slide transitions, in milliseconds
                    pager: true,           // Boolean: Show pager, true or false
                    nav: false,             // Boolean: Show navigation, true or false
                    random: false,          // Boolean: Randomize the order of the slides, true or false
                    pause: false,           // Boolean: Pause on hover, true or false
                    pauseControls: true,    // Boolean: Pause when hovering controls, true or false
                    prevText: "Vorige",   // String: Text for the "previous" button
                    nextText: "Volgende",       // String: Text for the "next" button
                    // maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
                    // navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
                    // manualControls: "",     // Selector: Declare custom pager navigation
                    // namespace: "rslides",   // String: Change the default namespace used
                    // before: function(){},   // Function: Before callback
                    // after: function(){}     // Function: After callback
                });

                $(".search-menu-item").on("click", function () {
                    $("#search").modal();
                });

                $(".modal-fullscreen").on('show.bs.modal', function () {
                    setTimeout(function () {
                        $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
                    }, 0);
                });
                $(".modal-fullscreen").on('shown.bs.modal', function () {
                    $('.addsearch').focus();
                });
                $(".modal-fullscreen").on('hidden.bs.modal', function () {
                    $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
                });

                $('.menu-btn').on('click', function () {
                    $('#primary-menu').addClass("open");
                    $('body').addClass("has-active-menu");
                    $('.c-mask').addClass("is-active");
                });
                $('.menu-btn-close').on('click', function () {
                    $('#primary-menu').removeClass("open");
                    $('body').removeClass("has-active-menu");
                    $('.c-mask').removeClass("is-active");
                });
                $(document).on("click", function (e) {
                    if ($(e.target).is("#primary-menu, .menu-btn, .menu-wrap") === false) {
                        $('#primary-menu').removeClass("open");
                        $('body').removeClass("has-active-menu");
                        $('.c-mask').removeClass("is-active");
                    }
                });
                $('#playPause,#videoAbout').on("click", function (e) {
	                if ($('#videoAbout').get(0).paused) {
		                $('#videoAbout').get(0).play();
                        $('#playPause').css({
                           'display': 'none'
                        });
	                } else {
		                $('#videoAbout').get(0).pause();
		                $('#playPause').css({
			                'display': 'initial'
		                });
	                }
                });

                $('#muteUnmute').on("click", function (e) {
	                var url = $('#muteUnmute img').attr('src');
	                if ($('#videoHome').get(0).muted === true) {
		                url = url.replace('-inactive', '');
		                $('#muteUnmute img').attr('src', url);
		                $('#videoHome').get(0).muted = false;
	                } else {
		                url = url.replace('icon-sound', 'icon-sound-inactive');
		                $('#muteUnmute img').attr('src', url);
		                $('#videoHome').get(0).muted = true;
	                }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                this.scrollTrans();

            },

            scrollTrans: function () {
                //caches a jQuery object containing the header element
                var header = $(".header-navigation");
                $(window).scroll(function () {
                    var scroll = $(window).scrollTop();

                    if (scroll >= 10) {
                        header.addClass("header-navigation-filled");
                    } else {
                        header.removeClass("header-navigation-filled");
                    }
                });
                header.on('show.bs.dropdown', function () {
                    $(this).addClass('dropdown-open');
                });
                header.on('hide.bs.dropdown', function () {
                    $(this).removeClass('dropdown-open');
                });
            },

            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'page_template_template_contact_php': {
            init: function () {
                $('#serviceAanvragenButton').on('click', function (e) {
                    console.log($(this).data('toggle'));
                    $('#contactformtabs a[href="#' + $(this).data('toggle') + '"]').tab('show');
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'post_type_archive_product': {
            init: function () {
                // JavaScript to be fired on the about us page
                var page = 1;
                var numPosts = 12;
                var maxPages = parseInt(pw_script_vars.maxPages);
                var totalPosts = parseInt(pw_script_vars.totalPosts);
                var tPath = (pw_script_vars.tPath);
                var is_term = (pw_script_vars.is_term);
                var tName = (pw_script_vars.termTaxonomy);
                var taxName = (pw_script_vars.taxName);
                var taxType = (pw_script_vars.taxType);
                var tID = parseInt(pw_script_vars.termID);
                var btn = $("#load-more");
                var content = $("#content");
                $('.term-' + taxName).addClass('active');

                if (totalPosts < numPosts) {
                    btn.prop("disabled", true).html('Geen producten meer');
                } else {
                    btn.click(function () {
                        page++;
                        $.ajax({
                            type: "GET",
                            data: (is_term) ? {
                              numPosts: numPosts,
                              pageNumber: page,
                              term: is_term,
                              termID: tID,
                              termTaxonomy: tName
                            } : {
                              numPosts: 12,
                              pageNumber:
                              page,
                              taxType:
                              taxType
                            },
                            dataType: "html",
                            url: tPath + "/lib/loopHandler.php",
                            beforeSend: function () {
                                if (page !== 1) {
                                    /*jshint multistr: true */
                                    content.addClass('ajax-loader');
                                }
                            },
                            success: function (data) {
                                $data = $(data);
                                if ($data.length) {
                                    $data.hide();
                                    content.append($data);
                                    $data.fadeIn(500, function () {
                                        content.removeClass('ajax-loader');

                                    });
                                } else {
                                    content.removeClass('ajax-loader');
                                }
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                content.removeClass('ajax-loader');
                                alert(jqXHR + " :: " + textStatus + " :: " + errorThrown);
                            }
                        });
                        if (page >= maxPages) {
                            setTimeout(function () {
                                btn.prop("disabled", true).html('Geen producten meer');
                            }, 1000);
                        }

                    });
                }
            }

        },
        'single_product': {
            init: function () {
                // JavaScript to be fired on the about us page
                this.singleCarousel();

                $(".rslides_single").responsiveSlides({
                    auto: true,             // Boolean: Animate automatically, true or false
                    speed: 500,             // Integer: Speed of the transition, in milliseconds
                    timeout: 10000,          // Integer: Time between slide transitions, in milliseconds
                    pager: true,           // Boolean: Show pager, true or false
                    nav: false,             // Boolean: Show navigation, true or false
                    random: false,          // Boolean: Randomize the order of the slides, true or false
                    pause: false,           // Boolean: Pause on hover, true or false
                    pauseControls: true,    // Boolean: Pause when hovering controls, true or false
                    prevText: "Vorige",   // String: Text for the "previous" button
                    nextText: "Volgende",       // String: Text for the "next" button
                    // maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
                    // navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
                    // manualControls: "",     // Selector: Declare custom pager navigation
                    // namespace: "rslides",   // String: Change the default namespace used
                    // before: function(){},   // Function: Before callback
                    // after: function(){}     // Function: After callback
                });


            },
            singleCarousel: function () {

                $('#product_carousel').carousel({
                    interval: 5000
                });

                //Handles the carousel thumbnails
                $('[id^=carousel-selector-]').click(function () {
                    var thumb = this.id.substr(this.id.lastIndexOf("-") + 1);
                    var id = parseInt(thumb);
                    $('#product_carousel').carousel(id);
                });
            }
        }


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

